import { useState, useEffect } from 'react';
import '../../styles/presets.css';
import '../../styles/react-select.css';
import Select from 'react-select';
import { useHistory } from 'react-router-dom'
import axios from 'axios';
import { connect } from 'react-redux'

const ReplacePresetValues = ({ products: { presetData }, user:{ userDetails } }) => {
    const [presetComp, setPresetComp] = useState([
        { type: "", presetid: "", replaceid: "" }
      ]);
    const [message, setmessage] = useState("")
    let history = useHistory()
    const pstypeHandler = (type, index) => {
        let tempData = [...presetComp];
        tempData[index].type = type;
        tempData[index].presetid = "";
        tempData[index].replaceid = "";
        setPresetComp(tempData);
      };
      useEffect(()=>{
        if(userDetails.accesslevel.replace_presets !== "edit"){
          history.push('/unauthorized')
          return;
      }
      },[presetData])
    const handleSelectPreset = (e, index, replace) => {
        let tempData = [...presetComp];
        if (replace) {
          tempData[index].replaceid = e.value;
          tempData[index].replaceTitle = e.label;
        } else {
          tempData[index].presetid = e.value;
          tempData[index].presetTitle = e.label;
        }
        setPresetComp(tempData);
      };

    const addComp = (preIndex) => {
        let prestCompData = [...presetComp];
        prestCompData.splice(preIndex + 1, 0, { type: "", presetid: "", replaceid: "" });
        setPresetComp(prestCompData);
      };

    const removeComp = (e, index) => {
        e.preventDefault();
        let tempData = [...presetComp];
        tempData.splice(index, 1);
        setPresetComp(tempData);
      };

    const handleSubmitReplace = async () => {
        try {
          const response = await axios.post(
            process.env.REACT_APP_KG_API_URL + 'api/kuroadmin/uppreinbuilds',
            presetComp
          );
          setmessage('Success' + response.data)
        } catch (error) {
          setmessage('Error' + error)
        }
      };

    return (
        <div className='presets txt-light-grey'>
          {userDetails.accesslevel.replace_presets === "edit"?
          <>
        <h2>Replace Preset Values</h2>
            {message && <p className='message'>{message}</p>}
            <div className='preset_body'>
          <h5>Select Preset to Replace</h5>
                <table className='border table_mob border full_width even_odd build'>
            <tbody>
              <tr>
                <th>Type</th>
                <th>Component</th>
                <th>Replace With</th>
              </tr>
              {presetData !== null && presetComp.map((preset, compIdx) => (
                <tr key={compIdx}>
                  <td>
                    <select
                      value={preset.type}
                      onChange={(e) => pstypeHandler(e.target.value, compIdx)}
                    >
                      <option value="">Select Type</option>
                      <option value="cpu">Processor</option>
                      <option value="mob">Motherboard</option>
                      <option value="ram">RAM</option>
                      <option value="gpu">Graphics Card</option>
                      <option value="ssd">SSD</option>
                      <option value="hdd">HDD</option>
                      <option value="psu">Power Supply</option>
                      <option value="cooler">Cooler</option>
                      <option value="tower">Case</option>
                      <option value="fans">Fans</option>
                      <option value="wifi">WiFi</option>
                      <option value="os">Operating System</option>
                      <option value="shp_fees">Shipping Fees</option>
                      <option value="build_fees">Build Charges</option>
                      <option value="warranty">Warranty</option>
                      <option value="margin">KM</option>
                    </select>
                  </td>
                  <td>
                    <Select
                      value={preset.presetid ? { value: preset.presetid, label: preset.presetTitle } : null}
                      classNamePrefix="kuro-search-select"
                      placeholder="Select Component"
                      className="react-select-container"
                      options={presetData.filter((preset) => preset.type === presetComp[compIdx].type)
                        .flatMap((comp) =>
                          comp.list.map((preset) => ({
                            value: preset.presetid,
                            label: preset.title
                          }))
                        )}
                      onChange={(e) => handleSelectPreset(e, compIdx, false)}
                    />
                  </td>
                  <td>
                    <Select
                      value={preset.replaceid ? { value: preset.replaceid, label: preset.replaceTitle } : null}
                      isDisabled={!preset.presetid}
                      classNamePrefix="kuro-search-select"
                      placeholder="Select Component"
                      className="react-select-container"
                      options={presetData.filter((preset) => preset.type === presetComp[compIdx].type)
                        .flatMap((comp) =>
                          comp.list.filter((preset) => presetComp[compIdx].presetid !== preset.presetid)
                            .map((preset) => ({
                              value: preset.presetid,
                              label: preset.title
                            }))
                        )}
                      onChange={(e) => handleSelectPreset(e, compIdx, true)}
                    />
                  </td>
                  <td>
                    <span className="prod-span" onClick={() => addComp(compIdx)}><svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-5v5h-4v-5h-5v-4h5v-5h4v5h5v4z" /></svg></span>
                    {presetComp.length > 1 && <span className="prod-span" onClick={(e) => removeComp(e, compIdx)}><svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-14v-4h14v4z" /></svg></span>}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {presetComp.every(preset => preset.replaceid !== "" && preset.presetid !== "") && <button onClick={handleSubmitReplace}>Replace</button>}
        <p>&nbsp;</p>
        </>:null}
      </div>
    )
    }

    const mapStateToProps = state => ({
    products: state.products,
    user: state.user
    })

    export default connect(mapStateToProps)(ReplacePresetValues)
