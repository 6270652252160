import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import { useHistory } from "react-router-dom"

const Counters = ({user: {token, userDetails}}) => {

    const [counterData, setcounterData] = useState(null)
    const [newcounterData, setnewcounterData] = useState(null)
    const [editFlag, seteditFlag] = useState(false)
    const history = useHistory()
    useEffect(() => {
        const access=userDetails.accesslevel.counters;
        if(access==="NA"){
            history.push('/unauthorized')
            return;
        }
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }
        axios(process.env.REACT_APP_KC_API_URL + 'kurostaff/counters', config).then(res => {
            setcounterData(res.data)
            setnewcounterData(res.data)
        })
    }, [])

    const updateCounters = (e, type) => {
        let tempcounter = {...counterData}
        tempcounter[type] = Number(e.target.value)
        setnewcounterData(tempcounter)
    }

    const updateeditFlag = () => {
        seteditFlag(true)
    }

    const submitHandler = () => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        }

        axios.post(process.env.REACT_APP_KC_API_URL + 'kurostaff/counters', newcounterData, config)
        .then((res) => {
            setcounterData(res.data)
        })

        seteditFlag(false)
    }

    const cancelHandler = () => {
        seteditFlag(false)
        setnewcounterData(counterData)
    }

    return (
        <div className="counters txt-light-grey">
            <h2>Kuro Counters</h2>
            {editFlag ? (
            <div className="c-wrap">
                <table className="border" cellPadding="0" cellSpacing="0">
                    <tbody>
                        <tr>
                            <th>TP Orders</th>
                            <td><input value={newcounterData.tporders} onChange={(e) => updateCounters(e, "tporders")} /></td>
                        </tr>
                        <tr>
                            <th>Vendors</th>
                            <td><input value={newcounterData.vendors} onChange={(e) => updateCounters(e, "vendors")} /></td>
                        </tr>
                        <tr>
                            <th>Purchase Orders</th>
                            <td><input value={newcounterData.purchaseorders} onChange={(e) => updateCounters(e, "purchaseorders")} /></td>
                        </tr>
                        <tr>
                            <th>Purchase Invoices</th>
                            <td><input value={newcounterData.inwardinvoices} onChange={(e) => updateCounters(e, "inwardinvoices")} /></td>
                        </tr>
                        <tr>
                            <th>Sales Invoices</th>
                            <td><input value={newcounterData.outwardinvoices} onChange={(e) => updateCounters(e, "outwardinvoices")} /></td>
                        </tr>
                        <tr>
                            <th>Inward Credit Notes</th>
                            <td><input value={newcounterData.inwardcreditnotes} onChange={(e) => updateCounters(e, "inwardcreditnotes")} /></td>
                        </tr>
                        <tr>
                            <th>Outward Credit Notes</th>
                            <td><input value={newcounterData.outwardcreditnotes} onChange={(e) => updateCounters(e, "outwardcreditnotes")} /></td>
                        </tr>
                        <tr>
                            <th>Inward Debit Notes</th>
                            <td><input value={newcounterData.inwarddebitnotes} onChange={(e) => updateCounters(e, "inwarddebitnotes")} /></td>
                        </tr>
                        <tr>
                            <th>Outward Debit Notes</th>
                            <td><input value={newcounterData.outwarddebitnotes} onChange={(e) => updateCounters(e, "outwarddebitnotes")} /></td>
                        </tr>
                        <tr>
                            <th>Payment Vouchers</th>
                            <td><input value={newcounterData.paymentvouchers} onChange={(e) => updateCounters(e, "paymentvouchers")} /></td>
                        </tr>
                        <tr>
                            <th>Estimates</th>
                            <td><input value={newcounterData.estimates} onChange={(e) => updateCounters(e, "estimates")} /></td>
                        </tr>
                        <tr>
                            <th>KG Orders</th>
                            <td><input value={newcounterData.kgorders} onChange={(e) => updateCounters(e, "kgorders")} /></td>
                        </tr>
                        <tr>
                            <th>Service Requests</th>
                            <td><input value={newcounterData.servicerequest} onChange={(e) => updateCounters(e, "servicerequest")} /></td>
                        </tr>
                    </tbody>
                </table>
                <ul className='btns'>
                    <li>
                        <button onClick={submitHandler}>Submit</button>
                    </li>
                    <li>
                        <button onClick={cancelHandler}>Cancel</button>
                    </li>
                </ul>
            </div>
            ) : (
            counterData !== null &&
            <div className="c-wrap">
                {userDetails.accesslevel.counters==="edit" && <ul className='btns'>
                    <li>
                        <button onClick={updateeditFlag}>Edit Counters</button>
                    </li>
                </ul>}
                
                <table className="border even_odd" cellPadding="0" cellSpacing="0">
                    <tbody>
                        <tr>
                            <td>TP Orders</td>
                            <td>{counterData.tporders}</td>
                        </tr>
                        <tr>
                            <td>Vendors</td>
                            <td>{counterData.vendors}</td>
                        </tr>
                        <tr>
                            <td>Purchase Orders</td>
                            <td>{counterData.purchaseorders}</td>
                        </tr>
                        <tr>
                            <td>Purchase Invoices</td>
                            <td>{counterData.inwardinvoices}</td>
                        </tr>
                        <tr>
                            <td>Sales Invoices</td>
                            <td>{counterData.outwardinvoices}</td>
                        </tr>
                        <tr>
                            <td>Inward Credit Notes</td>
                            <td>{counterData.inwardcreditnotes}</td>
                        </tr>
                        <tr>
                            <td>Outward Credit Notes</td>
                            <td>{counterData.outwardcreditnotes}</td>
                        </tr>
                        <tr>
                            <td>Inward Debit Notes</td>
                            <td>{counterData.inwarddebitnotes}</td>
                        </tr>
                        <tr>
                            <td>Outward Debit Notes</td>
                            <td>{counterData.outwarddebitnotes}</td>
                        </tr>
                        <tr>
                            <td>Payment Vouchers</td>
                            <td>{counterData.paymentvouchers}</td>
                        </tr>
                        <tr>
                            <td>Estimates</td>
                            <td>{counterData.estimates}</td>
                        </tr>
                        <tr>
                            <td>KG Orders</td>
                            <td>{counterData.kgorders}</td>
                        </tr>
                        <tr>
                            <td>Service Requests</td>
                            <td>{counterData.servicerequest}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            )}
        </div>
    )
}

const mapStateToProps = (state) => ({
    user: state.user
})

export default connect(mapStateToProps)(Counters)