import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useHistory } from 'react-router-dom'
import { psspecs } from '../../data/Presets'
import note from "../../assets/img/button.png"
import "../../styles/table.css"
import Select from 'react-select'
import { connect } from 'react-redux'
import { getVendors } from "../../actions/admin"
import CreatableSelect from 'react-select/creatable';

const Preset = ({ match, user: { userDetails }, admin: { vendors }, getVendors }) => {

    let history = useHistory()
    const createOption = (label) => ({
        label,
        value: label.toLowerCase().replace(/\W/g, ''),
    });
    const { params: { pstype, presetid } } = match
    const [options, setOptions] = useState([]);
    const [presetdata, setpresetdata] = useState(null)
    const [newPresetData, setNewPresetData] = useState(null)
    const [editflag, seteditflag] = useState(false)

    // useEffect(()=>{
    //     const config = {}
    //     axios(process.env.REACT_APP_KG_API_URL + 'kuroadmin/presets?pstype=' + pstype + '&presetid=' + presetid, config).then(res => {
    //         if (!res.data[0].overview) {
    //             res.data[0].overview = [];
    //         }
    //     setNewPresetData(res.data[0])
    //     setNewPresetData((prev) => { return { ...prev, "vendor_price": [{ "vendor": "", price: 0, created_by: "" }] } })
    //     })
    // },[editflag])

    useEffect(() => {
        const fetchVendors = async () => {
            await getVendors();

            if (vendors) {
                const defaultOptions = vendors.filter(item => item.type === "Trading Stock").map(item => ({ value: item.vendor_code, label: item.name }));
                setOptions(defaultOptions);
            }
        };
        fetchVendors();
    }, [getVendors, editflag]);


    useEffect(() => {
        if (userDetails.accesslevel.presets === "NA") {
            history.push('/unauthorized')
            return;
        }
        const config = {}
        axios(process.env.REACT_APP_KG_API_URL + 'kuroadmin/presets?pstype=' + pstype + '&presetid=' + presetid, config).then(res => {
            if (!res.data[0].overview) {
                res.data[0].overview = [];
            }
            setpresetdata(res.data[0])
            setNewPresetData(res.data[0])
            // setNewPresetData((prev) => { return { ...prev, "vendor_price": [{ "vendor": "", price: 0, created_by: "" }] } })

        })
    }, [presetid])

    useEffect(() => {
        const vendorPrice = newPresetData?.vendor_price;

        if (vendorPrice?.length) {
            const leastPrice = vendorPrice.reduce((a, b) =>
                a.price < b.price ? a : b
            ).price;

            if (leastPrice > 0) {
                const newPrice = leastPrice * 1.01;
                setNewPresetData(prevData => ({
                    ...prevData,
                    price: newPrice
                }));
            }
        }
    }, [JSON.stringify(newPresetData?.vendor_price)]);

    const updatepreset = (key, e, num, i) => {
        let temppreset = { ...newPresetData }
        if (key === "vendor_price") {
            temppreset.vendor_price[i].price = Number(e.target.value);
        } else {
            temppreset[key] = num ? Number(e.target.value) : e.target.value
        }
        setNewPresetData(temppreset)
    }

    const updatespecs = (key, value) => {
        let temppreset = { ...newPresetData }
        if (key === "supported_chipset") {
            temppreset.specs[key] = value
        } else {
            temppreset.specs[key] = value
        }
        setNewPresetData(temppreset)
    }
    const updateoverview = (i, key, value) => {
        let temppreset = { ...newPresetData }
        temppreset.overview[i][key] = value
        setNewPresetData(temppreset)
    }

    const updateImage = (key, index, e) => {
        let temppreset = { ...newPresetData }
        temppreset[key][index] = e.target.value
        setNewPresetData(temppreset)
    }

    const handleCreateOPtions = (inputValue) => {
        const newOption = createOption(inputValue);
        setOptions((prev) => [...prev, newOption]);
        setNewPresetData((prevData) => ({
            ...prevData,
            vendor_price: [
                {
                    ...prevData.vendor_price[0],
                    vendor: newOption,
                },
            ],
        }));
    };
    const addoverview = () => {
        let temppreset = { ...newPresetData }
        temppreset.overview.push({
            "title": "",
            "subtitle": "",
            "cta_button": "",
            "cta_link": "",
            "desc": ""
        })
        setNewPresetData(temppreset)
    }
    const removeoverview = (index) => {
        let temppreset = { ...newPresetData }
        temppreset.overview = temppreset.overview.filter((_, i) => i !== index)
        setNewPresetData(temppreset)
    }
    const addimage = () => {
        let temppreset = { ...newPresetData }
        temppreset.images.push("")
        setNewPresetData(temppreset)
    }

    const removeimage = (index) => {
        let temppreset = { ...newPresetData }
        temppreset.images = temppreset.images.filter((_, i) => i !== index)
        setNewPresetData(temppreset)
    }


    const editpreset = () => {
        setpresetdata(newPresetData)
        seteditflag(true)
    }

    const disableScroll = (e) => {
        e.target.blur()
    }

    const addVendor = () => {
        setNewPresetData(prevData => ({
            ...prevData, vendor_price: [...prevData.vendor_price, { vendor: "", price: 0 }]
        }))
    }

    const removeVendor = (index) => {
        setNewPresetData(prevData => ({
            ...prevData, vendor_price: prevData.vendor_price.filter((item, i) => i !== index)
        }))
    }

    const submitHandler = () => {
        const config = {}

        let newpsData = newPresetData
        for (let key of ["title", "kuro_title", "portal_title", "short_title"]) {
            newpsData[key] = newpsData[key].trim()
        }
        for (let spec in newpsData.specs) {
            let pskey = psspecs.filter(_ => _.type === pstype)[0].specs.filter(_ => _.id === spec)[0]
            if (pskey) {
                if (pskey.type === "dec") {
                    newpsData.specs[spec] = parseFloat(newpsData.specs[spec])
                } else if (pskey.type === "txt") {
                    newpsData.specs[spec] = newpsData.specs[spec].trim()
                }
            }
        }
        console.log("submittedData:", newpsData)
        axios.post(process.env.REACT_APP_KG_API_URL + 'kuroadmin/presets?pstype=' + pstype + '&presetid=' + presetid, newpsData, config)
            .then((res) => {
                setpresetdata(res.data[0])
            })
        seteditflag(false)
    }

    const cancelHandler = () => {
        seteditflag(false)
        setNewPresetData(presetdata)
    }

    const backHandler = () => {
        history.push({
            pathname: '/presets',
            state: { pstype }
        })
    }

    return (
        <div className='txt-light-grey'>
            <h1>Preset</h1>
            <div className="note">
                <div className="note_head">
                    <h5>Instructions <img src={note} alt='instruction' className='note_img' />
                        <span className="instructions">
                            <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit.</p>
                        </span>
                    </h5>
                    <p>Note: <br />
                        <span className="note_text">
                            <b> Customer Title</b>: For Customer [estimates, offline ordres, invoices]<br />
                            <b> Kuro Title</b>: For Kuro Admin & Team Portal (Actual Product)<br />
                            <b>Portal Title:</b> For kurogaming.com [Builds]<br />
                            <b>Short Title</b>: For kurogaming.com [Variations and other purposes where short title is needed]<br />
                        </span>
                    </p>
                </div>

            </div>
            <div className='preset_wrapper'>
                {editflag ? (
                    <div className='preset_form'>
                        <div className='preset_data'>
                            <table className='border'>
                                <tbody>
                                    <tr>
                                        <th className="large">Customer Title</th>
                                        <td className="large">
                                            <textarea value={newPresetData.title} onChange={(e) => updatepreset("title", e)} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="large">Kuro Title</th>
                                        <td className="large">
                                            <textarea value={newPresetData.kuro_title} onChange={(e) => updatepreset("kuro_title", e)} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="large">Portal Title</th>
                                        <td className="large">
                                            <textarea value={newPresetData.portal_title} onChange={(e) => updatepreset("portal_title", e)} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="large">Short Title</th>
                                        <td className="large">
                                            <textarea value={newPresetData.short_title} onChange={(e) => updatepreset("short_title", e)} />
                                        </td>
                                    </tr>
                                    {pstype !== "os" && pstype !== "shp_fees" && pstype !== "build_fees" && pstype !== "warranty" &&
                                        <>
                                            <tr>
                                                <th className="large" colSpan="2">Specs</th>
                                            </tr>
                                            {Object.keys(newPresetData.specs).map((spec, i) =>
                                                psspecs.filter(_ => _.type === pstype)[0].specs.filter(_ => _.id === spec).map((pskey, j) =>
                                                    <tr key={i + "" + j}>
                                                        <th>{pskey.text}</th>
                                                        <td>
                                                            {(pskey.type === "txt" || pskey.type === "dec") &&
                                                                <input value={newPresetData.specs[spec]} onChange={(e) => updatespecs(spec, e.target.value)} />
                                                            }
                                                            {pskey.type === "num" &&
                                                                <input type="number" value={newPresetData.specs[spec]} onChange={(e) => updatespecs(spec, Number(e.target.value))} />
                                                            }
                                                            {pskey.type === "bool" &&
                                                                <div style={{ display: "flex" }}>
                                                                    <span>Yes</span><input type="radio" value={true} checked={newPresetData.specs[spec] === true} onChange={() => updatespecs(spec, true)} />
                                                                    <span>No</span><input type="radio" value={false} checked={newPresetData.specs[spec] === false} onChange={(e) => updatespecs(spec, false)} />
                                                                </div>
                                                            }
                                                            {pskey.type === "select" &&
                                                                <select onChange={(e) => updatespecs(spec, e.target.value)} value={newPresetData.specs[spec]}>
                                                                    <option value="">Select {pskey.text}</option>
                                                                    {pskey.options.map((opt, j) =>
                                                                        <option value={opt}>{opt}</option>
                                                                    )}
                                                                </select>
                                                            }
                                                            {
                                                                pskey.type === "multiselect" &&
                                                                <Select
                                                                    classNamePrefix="kuro-search-select"
                                                                    options={pskey.options}
                                                                    value={newPresetData.specs[spec]}
                                                                    onChange={(e) => updatespecs("supported_chipset", e)}
                                                                    placeholder="Select Supported Chipsets"
                                                                    className="react-select-container"
                                                                    isMulti
                                                                    closeMenuOnSelect={false}
                                                                />

                                                            }
                                                            {pskey.postfix && pskey.postfix}
                                                        </td>
                                                    </tr>
                                                )
                                            )}
                                            <tr>
                                                <th style={{ height: '50px' }} colSpan="2">Overview<span className='checkbox' onClick={() => addoverview()}><svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-5v5h-4v-5h-5v-4h5v-5h4v5h5v4z" /></svg></span></th>
                                            </tr>
                                            {newPresetData.overview.length > 0 && (
                                                newPresetData.overview.map((obj, i) => {
                                                    return (
                                                        <>
                                                            <th colSpan='2' >Overview {i + 1} <span onClick={() => removeoverview(i)} style={{ marginLeft: "10px" }}><svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-14v-4h14v4z" /></svg></span></th>
                                                            {Object.keys(obj).map((key, j) => {
                                                                return (
                                                                    <tr key={j}>
                                                                        <th style={{ textTransform: 'capitalize' }}>{key.replace(/_/g, ' ')}</th>
                                                                        <td>
                                                                            <textarea value={obj[key]} onChange={(e) => updateoverview(i, key, e.target.value)} />
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })}
                                                        </>
                                                    );
                                                })
                                            )}
                                            {pstype !== "os" && pstype !== "shp_fees" && pstype !== "build_fees" && pstype !== "warranty" &&
                                                <tr>
                                                    <th style={{ height: '50px' }} colSpan="2">Images<span className='checkbox' onClick={() => addimage()}><svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-5v5h-4v-5h-5v-4h5v-5h4v5h5v4z" /></svg></span></th>
                                                </tr>
                                            }
                                            {newPresetData.images && newPresetData.images.map((img, i) =>
                                                <tr>
                                                    <td key={i} colSpan="2"><input className='large' value={img} onChange={(e) => updateImage("images", i, e)} />
                                                        <span onClick={() => removeimage(i)} style={{ marginLeft: "10px" }}><svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-14v-4h14v4z" /></svg></span>
                                                        <span onClick={() => addimage()} style={{ margin: "10px" }}><svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-5v5h-4v-5h-5v-4h5v-5h4v5h5v4z" /></svg></span>
                                                    </td>
                                                </tr>
                                            )}
                                        </>
                                    }
                                    <tr>
                                        <th className="medium">Stock Availability</th>
                                        <td className="medium">
                                            <select value={newPresetData.status} onChange={(e) => updatepreset("status", e)}>
                                                <option value="">Select Stock Availability</option>
                                                <option value="In Stock">In Stock</option>
                                                <option value="Currently Unavailable">Currently Unavailable</option>
                                                <option value="Check Availability">Check Availability</option>
                                            </select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="small">Price</th>
                                        <td className="small">
                                            <input type="number" onWheel={disableScroll} value={newPresetData.price} onChange={(e) => updatepreset("price", e, true)} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="small">MRP</th>
                                        <td className="small">
                                            <input type="number" onWheel={disableScroll} value={newPresetData.mrp} onChange={(e) => updatepreset("mrp", e, true)} />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <ul className='btns'>
                                <li><button onClick={submitHandler}>Submit</button></li>
                                <li><button onClick={cancelHandler}>Cancel</button></li>
                            </ul>
                        </div>
                        <div>
                            <table className="border vendor_table minimum_height">
                                <tbody>
                                    <tr>
                                        <th className='small '>Vendor price
                                            <div onClick={() => addVendor()} ><svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-5v5h-4v-5h-5v-4h5v-5h4v5h5v4z" /></svg></div>
                                        </th>
                                        {newPresetData.vendor_price != null && newPresetData.vendor_price.length > 0 && newPresetData.vendor_price.map((item, i) => (
                                            <tr>
                                                <td className='display-grid'>
                                                    <VendorPriceSelector
                                                        key={i}
                                                        i={i}
                                                        item={item}
                                                        removeVendor={removeVendor}
                                                        addVendor={addVendor}
                                                        handleCreateOPtions={handleCreateOPtions}
                                                        options={options}
                                                        updatepreset={updatepreset}
                                                        setNewPresetData={setNewPresetData}
                                                        newPresetData={newPresetData}
                                                    />
                                                </td>
                                                <td>
                                                    {newPresetData.vendor_price.length > 0 &&
                                                        <span onClick={() => removeVendor(i)} style={{ marginRight: 5 }}><svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-14v-4h14v4z" /></svg></span>}

                                                    <span onClick={() => addVendor()} style={{ marginRight: 5 }}><svg fill="#92abcf" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7 14h-5v5h-4v-5h-5v-4h5v-5h4v5h5v4z" /></svg></span>
                                                </td>
                                            </tr>
                                        ))}
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                ) : (
                    <div>
                        <ul className="btns">
                            <li>{userDetails.accesslevel.presets === "edit" ? <button onClick={editpreset}>Edit the Preset Details</button> : null}</li>
                        </ul>
                        <div className='preset_body'>
                            {presetdata !== null &&
                                <table className='border table_mob'>
                                    <tbody>
                                        <tr>
                                            <th>Preset Id</th>
                                            <td>{presetdata.presetid}</td>
                                        </tr>
                                        <tr>
                                            <th>Customer Title</th>
                                            <td>{presetdata.title}</td>
                                        </tr>
                                        <tr>
                                            <th>Kuro Title</th>
                                            <td>{presetdata.kuro_title}</td>
                                        </tr>
                                        <tr>
                                            <th>Portal Title</th>
                                            <td>{presetdata.portal_title}</td>
                                        </tr>
                                        <tr>
                                            <th>Short Title</th>
                                            <td>{presetdata.short_title}</td>
                                        </tr>
                                        {pstype !== "os" && pstype !== "shp_fees" && pstype !== "build_fees" && pstype !== "warranty" &&
                                            <tr>
                                                <th>Specs</th>
                                                <td style={{ whiteSpace: "nowrap" }}>
                                                    {Object.keys(presetdata.specs).map((spec) =>
                                                        <>{psspecs.filter(_ => _.type === pstype)[0].specs.filter(_ => _.id === spec)[0].text}:&nbsp;
                                                            {typeof (presetdata.specs[spec]) === "boolean" ? (presetdata.specs[spec] === true ? 'Yes' : 'No') : presetdata.specs[spec]}
                                                            {psspecs.filter(_ => _.type === pstype)[0].specs.filter(_ => _.id === spec)[0].postfix && psspecs.filter(_ => _.type === pstype)[0].specs.filter(_ => _.id === spec)[0].postfix}
                                                            <br />
                                                        </>
                                                    )}
                                                </td>
                                            </tr>
                                        }
                                        <tr>
                                            <th>Overview</th>
                                            <td>
                                                {presetdata.overview.map((ov, i) =>
                                                    <span key={i}>
                                                        {ov.title && <span>{ov.title}<br></br></span>}
                                                        {ov.subtitle && <span>{ov.subtitle}<br></br></span>}
                                                        {ov.desc && <span>{ov.desc}</span>}
                                                    </span>
                                                )}
                                            </td>
                                        </tr>
                                        {pstype !== "os" && pstype !== "shp_fees" && pstype !== "build_fees" && pstype !== "warranty" &&
                                            <tr>
                                                <th>Images</th>
                                                <td style={{ whiteSpace: "nowrap" }}>{presetdata.images.map(img => <>{img}<br /></>)}</td>
                                            </tr>
                                        }
                                        <tr>
                                            <th>Stock Availability</th>
                                            <td>{presetdata.status}</td>
                                        </tr>
                                        <tr>
                                            <th>Price</th>
                                            <td>{presetdata.price}</td>
                                        </tr>
                                        <tr>
                                            <th>MRP</th>
                                            <td>{presetdata.mrp}</td>
                                        </tr>
                                        <tr>
                                            <th>Vendor Price</th>
                                            <td>
                                                {presetdata.vendor_price.length > 0
                                                    ?
                                                    <div className="vendor-container">
                                                        {/* {presetdata.vendor_price.map((vendor, index) => (
                                                                <div>
                                                            <span className="vendor-name">{vendor.vendor}</span>
                                                            <span className="vendor-price">{vendor.price}</span>
                                                            </div>))} */}
                                                        <table className='border'>
                                                            <thead>
                                                                <tr>
                                                                    <th>vendor</th>
                                                                    <th>price</th>
                                                                    <th>Created By</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody >
                                                                {presetdata.vendor_price.map((vendor, index) => (
                                                                    <tr key={index}>
                                                                        <td>{vendor.vendor}</td>
                                                                        <td>{vendor.price}</td>
                                                                        <td>{vendor.created_by}</td>
                                                                    </tr>))}
                                                            </tbody>
                                                        </table>
                                                    </div>

                                                    : "No Price Available"}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            }
                            <ul className='btns'>
                                <li><button onClick={backHandler}>Back</button></li>
                            </ul>
                        </div>
                    </div>
                )}

            </div>

            <p>&nbsp;</p>
        </div>
    )
}

const mapStateToProps = state => ({
    user: state.user,
    admin: state.admin,
})

export const VendorPriceSelector = ({ removeVendor, addVendor, item, handleCreateOPtions, options, i, updatepreset, setNewPresetData, newPresetData }) => {


    return (
        <>

            <CreatableSelect
                isClearable
                onChange={(newValue) => setNewPresetData((prevData) => {
                    let temppreset = { ...prevData };

                    if (newValue === null) {
                        // Handle the case when the user clears the selection
                        temppreset.vendor_price[i].vendor = '';
                    } else {
                        // Handle the case when the user selects or creates an option
                        temppreset.vendor_price[i].vendor = newValue.label;
                    }

                    return temppreset;
                })}
                onCreateOption={handleCreateOPtions}
                options={options}
                value={options.find(option => option.label === item.vendor) || ""}
                formatCreateLabel={(inputValue) => `Add "${inputValue}"`}
                placeholder="Select or create a vendor"
            // noOptionsMessage="No vendors found"
            />
            <input
                type="number"
                value={item.price}
                placeholder='Enter Price'
                onChange={(e) => updatepreset("vendor_price", e, true, i)}
            />
        </>
    );
};
export default connect(mapStateToProps, { getVendors })(Preset)

