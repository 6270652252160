import React, { Fragment, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { logout } from '../../actions/user'
import KuroLink from '../common/KuroLink'
import { Redirect } from "react-router"
import '../../styles/header.css'
import SearchBar from './SearchBar'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'

const Header = ({ user: { isAuthenticated, userDetails, redirectTo }, logout }) => {
    const [flag, setFlag] = useState([{ name: "Accounts", access: false }, { name: "Order Management", access: false }, { name: "Inventory", access: false }, { name: "Service", access: false }, { name: "Products", access: false }, { name: "HR", access: false }])
    let top = 0
    const location = useLocation()

    const navData = [
        {
            name: "Accounts",
            details: [
                { subNav: "Purchase Invoices", path: "inward-invoices", key: "inward_invoices" },
                { subNav: "Purchase Credit Notes", path: "inward-creditnotes", key: "inward_creditnotes" },
                { subNav: "Purchase Debit Notes", path: "inward-debitnotes", key: "inward_debitnotes" },
                { subNav: "Sales Invoices", path: "outward-invoices", key: "outward_invoices" }, {
                    subNav: "Sales Credit Notes", path: "outward-creditnotes", key: "outward_creditnotes"
                },
                { subNav: "Sales Debit Notes", path: "outward-debitnotes", key: "outward_debitnotes" }, {
                    subNav: "Purchase Orders (PO)", path: "purchase-orders", key: "purchase_orders"
                }, {
                    subNav: "Payment Vouchers", path: "payment-vouchers", key: "paymentvouchers"
                },
                { subNav: "Counters", path: "counters", key: "counters" },
                { subNav: "Vendors", path: "vendors", key: "vendors" },
                { subNav: "Export Data", path: "exportdata", key: "export_data" },
                { subNav: "Financials", path: "financials", key: "financials" },
                { subNav: "ITC GST", path: "itc-gst", key: "itc_gst" },

            ]
        },
        {
            name: "Order Management",
            details: [
                { subNav: "User List", path: "users", key: "user_list" },
                { subNav: "Estimates", path: "estimates", key: "estimates" },
                { subNav: "Inward Payments", path: "inward-payments", key: "inward_payments" },
                { subNav: "TP Orders", path: "tporders", key: "orders" },
                { subNav: "Offline Orders", path: "offlineorders", key: "offline_orders" },
                { subNav: "Online Orders", path: "orders", key: "online_orders" },
                { subNav: "Indent/Batches", path: "indent-list", key: "indent" }
            ]
        },

        {
            name: "Inventory",
            details: [
                { subNav: "TP Builds", path: "tpbuilds", key: "tp_builds" },
                { subNav: "Inventory ", path: "stock/inventory", key: "inventory" },
                { subNav: "Stock Register", path: "stock-register", key: "stock" },
                { subNav: "Audit", path: "audit", key: "audit" }]
        },
        {
            name: "Service",
            details: [
                { subNav: "Service Request", path: "service-request", key: "service_request" }]
        },
        {
            name: "Products", details: [
                { subNav: "Products", path: "products", key: "products" },
                { subNav: "Presets", path: "presets", key: "presets" },
                { subNav: "Replace Presets Values", path: "replace-preset-values", key: "replace_presets" },
                { subNav: "Products Finder", path: "productfinder", key: "product_finder" },
                { subNav: "Pre Builds Finder", path: "prebuiltsfinder", key: "pre_builts_finder" },
                { subNav: "Peripherals", path: "peripherals", key: "peripherals" },
                { subNav: "Portal Editor", path: "portaleditor", key: "portal_editor" }
            ]
        },
        {
            name: "HR", details: [
                { subNav: "Employees", path: "employees", key: "employees" },
                { subNav: "Employee Access Level", path: "employee-accesslevel", key: "employee_accesslevel" },
                { subNav: "Job Application", path: "job-apps", key: "job_application" },
                { subNav: "Attendance", path: "attendance", key: "emp_attendance" },
                { subNav: "Employees Salary", path: "emps-salary", key: "employees_salary" },
            ]
        },

    ]

    const HandleUserAccess = () => {
        let updatedFlags = [...flag];

        navData.forEach((nav) => {
            nav.details.forEach((subNav) => {
                if ((subNav.key === "replace_presets" && userDetails.accesslevel.replace_presets === "edit") ||
                    (userDetails && userDetails.accesslevel[subNav.key] !== "NA")) {
                    updatedFlags = updatedFlags.map((ele) => ele.name === nav.name ? { ...ele, access: true } : { ...ele });
                }
            });
        });

        setFlag(updatedFlags);
    };


    useEffect(() => {
        window.addEventListener("scroll", stickyHandler)
        HandleUserAccess()
        return () => {
            window.removeEventListener("scroll", () => stickyHandler)
        }
    }, [userDetails])

    const menuCloseHandler = () => {
        document.querySelector('.header').classList.remove('hb_open')
        document.body.classList.remove('hb_open')
    }

    const stickyHandler = () => {
        if (top < window.pageYOffset && window.pageYOffset > 100) {
            document.querySelector('.header').classList.remove('sticky')
        } else {
            document.querySelector('.header').classList.add('sticky')
        }
        top = window.pageYOffset
    }

    const menuHandler = (e) => {
        e.target.closest('.header').classList.toggle('hb_open')
        document.body.classList.toggle('hb_open')
    }

    const itemDropHandler = (e) => {

        if (e.target.closest('.item').classList.contains('open')) {
            e.target.closest('.item').classList.remove('open')
        } else {
            document.querySelectorAll('.header .menu .item').forEach(el => {
                el.classList.remove('open')
            })
            e.target.closest('.item').classList.add('open')
        }

    }

    const logoutHandler = () => {
        logout()
    }
    //console.log(flag)
    return (
        <Fragment>
            {redirectTo && <Redirect to={redirectTo} />}
            <header className="header sticky pg_mh">
                <div className="banner1">
                    <div className="logo">
                        <KuroLink to="/">
                            <div className="text_logo">
                                <svg fill="#ccc" viewBox="0 0 1117 399.95"><title>Kuro Cadence Team</title><path d="M314.08,695.75l-59.23,0L142.59,576.61V695.75H105V304.83h37.59V571.05L248.18,466.46l53.56.14L190,572.4Z" transform="translate(-105 -304.83)" /><path d="M620,616.29Q615.7,651,596.05,674t-40.71,26.69q-21.06,3.76-30.53,3.77-27.46,0-50.18-12.33t-35-37.52A132.91,132.91,0,0,1,432,621.33V462.7h41.35V601.2q0,27.28,14.14,47.76t37.78,20.47h5.57q19.47-2.53,33.61-18a51.93,51.93,0,0,0,14.14-36.23V462.7H620Z" transform="translate(-105 -304.83)" /><path d="M905.55,473.13,882.9,506.37q-12.27-11.58-25.48-11.58-18.4,0-33.5,19.39c-10.07,12.92-19.78,42.55-19.78,42.55V699.51H766.56v-233h33.83V522a123.32,123.32,0,0,1,22.82-42.05Q839,461,859.78,458,884.32,458,905.55,473.13Z" transform="translate(-105 -304.83)" /><path d="M1222,581.91q0,50.87-34.67,86.86t-83.29,36q-49.07,0-83.74-36T985.62,581.4q0-51.85,33.73-88.88t82.34-37q49.55,0,84.92,36.76T1222,581.91Zm-35.39,0q0-36.54-23.35-62.33t-56.85-25.79q-34.46,0-58.75,24.79t-24.3,60.32q0,35.55,23.6,61.59t57.08,26q33.51,0,58-24.54T1186.61,581.9Z" transform="translate(-105 -304.83)" /></svg>
                                {userDetails && userDetails.access == "Super" ? <span>Admin</span> : <span>Team</span>}

                            </div>
                        </KuroLink>
                    </div>
                    {location.pathname !== "/search-results" && <SearchBar />}
                    <div className="account">
                        {isAuthenticated ? (
                            <button className="txt_link">
                                <svg width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm0 22c-3.123 0-5.914-1.441-7.749-3.69.259-.588.783-.995 1.867-1.246 2.244-.518 4.459-.981 3.393-2.945-3.155-5.82-.899-9.119 2.489-9.119 3.322 0 5.634 3.177 2.489 9.119-1.035 1.952 1.1 2.416 3.393 2.945 1.082.25 1.61.655 1.871 1.241-1.836 2.253-4.628 3.695-7.753 3.695z" /></svg>
                                <span>{userDetails && userDetails.name}</span>
                            </button>
                        ) : (
                            <KuroLink to="/login">
                                <svg width="24" height="24" viewBox="0 0 24 24"><path d="M12 2c2.757 0 5 2.243 5 5.001 0 2.756-2.243 5-5 5s-5-2.244-5-5c0-2.758 2.243-5.001 5-5.001zm0-2c-3.866 0-7 3.134-7 7.001 0 3.865 3.134 7 7 7s7-3.135 7-7c0-3.867-3.134-7.001-7-7.001zm6.369 13.353c-.497.498-1.057.931-1.658 1.302 2.872 1.874 4.378 5.083 4.972 7.346h-19.387c.572-2.29 2.058-5.503 4.973-7.358-.603-.374-1.162-.811-1.658-1.312-4.258 3.072-5.611 8.506-5.611 10.669h24c0-2.142-1.44-7.557-5.631-10.647z" /></svg>
                                <span>Login</span>
                            </KuroLink>
                        )}
                        <div className="account_details">
                            {!isAuthenticated &&
                                <>
                                    <KuroLink to="/login" classList="btn signin solid pri1">Sign In</KuroLink>
                                </>
                            }
                            <ul className="no_bullets">
                                <li><KuroLink to="/user/profile"><svg fill="#00bceb" width="24" height="24" viewBox="0 0 24 24"><path d="M12 2c2.757 0 5 2.243 5 5.001 0 2.756-2.243 5-5 5s-5-2.244-5-5c0-2.758 2.243-5.001 5-5.001zm0-2c-3.866 0-7 3.134-7 7.001 0 3.865 3.134 7 7 7s7-3.135 7-7c0-3.867-3.134-7.001-7-7.001zm6.369 13.353c-.497.498-1.057.931-1.658 1.302 2.872 1.874 4.378 5.083 4.972 7.346h-19.387c.572-2.29 2.058-5.503 4.973-7.358-.603-.374-1.162-.811-1.658-1.312-4.258 3.072-5.611 8.506-5.611 10.669h24c0-2.142-1.44-7.557-5.631-10.647z" /></svg> Profile</KuroLink></li>
                                {isAuthenticated && <li><button className="txt_link" onClick={logoutHandler}><svg fill="#00bceb" width="24" height="24" viewBox="0 0 24 24" fillRule="evenodd" clipRule="evenodd"><path d="M16 2v7h-2v-5h-12v16h12v-5h2v7h-16v-20h16zm2 9v-4l6 5-6 5v-4h-10v-2h10z" /></svg> Logout</button></li>}
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="banner2">
                    <div className="menu">

                        <div className="hb_menu" onClick={menuHandler}>
                            <span></span>
                        </div>
                        <div className="menu_mask" onClick={menuCloseHandler}></div>

                        <div className="items">
                            {navData.map((data) =>
                                flag.map(e => e.name == data.name && e.access === true ? (<div className="item drop"><div key={e}><h3 className="pri-4" onClick={itemDropHandler}>{data.name}</h3>
                                    <ul className="no_bullets">
                                        {data.details.map((ele) =>
                                            userDetails && userDetails.accesslevel[ele.key] !== "NA" ? (<li key={ele.path}><KuroLink to={`/${ele.path}`}>{ele.subNav}</KuroLink></li>) : null
                                        )}

                                    </ul>
                                </div> </div>) : null)
                            )}
                        </div>
                    </div>
                </div>
            </header>
            <div className="header_spacer"></div>
        </Fragment>
    )
}

const mapStateToProps = (state) => ({
    user: state.user
})

export default connect(mapStateToProps, { logout })(Header)