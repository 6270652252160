import React, { useEffect, useState } from 'react';
import '../styles/table.css';
import { connect } from 'react-redux';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

const formatData = (data) => {
    const groupedData = {};
    data.forEach(({ total, invoice_type, year, month }) => {
        const key = `${month}-${year}`;
        if (!groupedData[key]) {
            groupedData[key] = {
                "Expenses - Office Supplies": 0,
                "Expenses - Logistics": 0,
                "Expenses - Services": 0,
                "Expenses - Food & Entertainment": 0,
                "Fixed Assets": 0,
                "Trading Stock": 0,
                "Expenses - Utilities": 0,
                "Expenses - Rent": 0,
                "Expenses - Salaries": 0,
                "Expenses - Financial Services": 0,
                "Expenses - Petty Cash": 0,
                "Govt Fees and Taxes": 0,
                "Govt Penalties": 0,
                "Bank Loan": 0
            };
        }
        groupedData[key][invoice_type] += total;
    });
    return groupedData;
};

const Financials = ({ user: { token, userDetails } }) => {
    const [finData, setFinData] = useState([]);
    const [formattedData, setFormattedData] = useState({});
    const history = useHistory()

    useEffect(() => {
        if (userDetails.access !== "Super") {
            history.push("/unauthorized")
            return;
        }
    }, [])

    useEffect(() => {
        const fetchFinancials = async () => {
            try {
                const config = {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Token ${token}`,
                    },
                };
                const { data } = await axios.get(process.env.REACT_APP_KC_API_URL + 'kuroadmin/financials', config);
                setFinData(data);
            } catch (error) {
                console.error('Error fetching financial data:', error);
            }
        };
        fetchFinancials();
    }, [token]);

    useEffect(() => {
        if (finData.length > 0) {
            setFormattedData(formatData(finData));
        }
    }, [finData]);

    const getTotalExpenses = (dataObj) => {
        return Object.entries(dataObj)
            .filter(([key]) => !["Fixed Assets", "Trading Stock"].includes(key))
            .reduce((sum, [, value]) => sum + value, 0);
    };

    const formatMonthYear = (dateString) => {
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const [month, year] = dateString.split('-');
        return `${months[parseInt(month) - 1]}-${year}`;
    };

    return (
        <div>
            {(userDetails.access === "Super") &&
                (<table className='border even_odd' cellPadding={0} cellSpacing={0}>
                    <thead>
                        <tr>
                            <th>Month</th>
                            <th>Fixed Assets</th>
                            <th>Trading Stock</th>
                            <th>Total Expenses</th>
                            <th>Salaries</th>
                            <th>Rent</th>
                            <th>Office Supplies</th>
                            <th>Logistics</th>
                            <th>Services</th>
                            <th>Food & Entertainment</th>
                            <th>Utilities</th>
                            <th>Office Supplies</th>
                            <th>Financial Services</th>
                            <th>Petty Cash</th>
                            <th>Bank Loan</th>
                            <th>Govt Fees and Taxes</th>
                            <th>Govt Penalties</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.keys(formattedData).map((date) => (
                            <tr key={date}>
                                <td>{formatMonthYear(date)}</td>
                                <td>{formattedData[date]["Fixed Assets"].toFixed(2)}</td>
                                <td>{formattedData[date]["Trading Stock"].toFixed(2)}</td>
                                <td>{getTotalExpenses(formattedData[date]).toFixed(2)}</td>
                                <td>{formattedData[date]["Expenses - Salaries"].toFixed(2)}</td>
                                <td>{formattedData[date]["Expenses - Rent"].toFixed(2)}</td>
                                <td>{formattedData[date]["Expenses - Office Supplies"].toFixed(2)}</td>
                                <td>{formattedData[date]["Expenses - Logistics"].toFixed(2)}</td>
                                <td>{formattedData[date]["Expenses - Services"].toFixed(2)}</td>
                                <td>{formattedData[date]["Expenses - Food & Entertainment"].toFixed(2)}</td>
                                <td>{formattedData[date]["Expenses - Utilities"].toFixed(2)}</td>
                                <td>{formattedData[date]["Expenses - Office Supplies"].toFixed(2)}</td>
                                <td>{formattedData[date]["Expenses - Financial Services"].toFixed(2)}</td>
                                <td>{formattedData[date]["Expenses - Petty Cash"].toFixed(2)}</td>
                                <td>{formattedData[date]["Bank Loan"].toFixed(2)}</td>
                                <td>{formattedData[date]["Govt Fees and Taxes"].toFixed(2)}</td>
                                <td>{formattedData[date]["Govt Penalties"].toFixed(2)}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>)}
        </div>
    );
};

const mapStateToProps = state => ({
    user: state.user,
});

export default connect(mapStateToProps)(Financials);