import { useEffect, useState } from 'react'
import axios from 'axios'
import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom'
import KuroLink from '../../components/common/KuroLink'
import '../../styles/presets.css'
import { psspecs } from '../../data/Presets'
import { useHistory } from "react-router-dom"

const Presets = ({ user: { userDetails } }) => {

    const location = useLocation()
    const [pstype, setpstype] = useState("")
    const [presetdata, setpresetdata] = useState([])
    const history = useHistory();

    useEffect(() => {
        if (userDetails.accesslevel.presets === "NA") {
            history.push('/unauthorized')
            return;
        }
        if (location.state && location.state.pstype) {
            const presettype = location.state.pstype
            setpstype(presettype)
            getpresets(pstype)
        }
    }, [location.state, pstype])

    const getpresets = () => {
        if (pstype !== "") {
            const config = {}
            axios(process.env.REACT_APP_KG_API_URL + 'kuroadmin/presets?pstype=' + pstype, config).then(res => {
                setpresetdata(res.data)
            })
        }
    }

    const pstypeHandler = (e) => {
        setpresetdata([])
        setpstype(e.target.value)
    }

    return (
        <div className='presets txt-light-grey'>
            <h2>Presets</h2>
            <ul className='btns'>
                {userDetails.access === "Super" ? <li><KuroLink to={'add-preset'}><button>Add Preset</button></KuroLink></li> : null}
            </ul>
            <div className='preset_head'>
                <table>
                    <tbody>
                        <tr>
                            <td>
                                <select className='inputsel' value={pstype} onChange={pstypeHandler}>
                                    <option value="">Select Type</option>
                                    <option value="cpu">Processor</option>
                                    <option value="mob">Motherboard</option>
                                    <option value="ram">RAM</option>
                                    <option value="gpu">Graphics Card</option>
                                    <option value="ssd">SSD</option>
                                    <option value="hdd">HDD</option>
                                    <option value="psu">Power Supply</option>
                                    <option value="cooler">Cooler</option>
                                    <option value="tower">Case</option>
                                    <option value="fans">Fans</option>
                                    <option value="wifi">WiFi</option>
                                    <option value="os">Operating System</option>
                                    <option value="shp_fees">Shipping Fees</option>
                                    <option value="build_fees">Build Charges</option>
                                    <option value="warranty">Warranty</option>
                                    <option value="margin">KM</option>
                                </select>
                            </td>
                            <td><button onClick={getpresets}>Get Details</button></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            {pstype && presetdata.length > 0 &&
                <div className='preset_body'>
                    <table className='border table_mob'>
                        <tbody>
                            <tr>
                                <th>Preset Id</th>
                                <th>Customer Title</th>
                                <th>Kuro Title</th>
                                <th>Portal Title</th>
                                <th className='show_tab'>Short Title</th>
                                {pstype !== "os" && pstype !== "shp_fees" && pstype !== "build_fees" && pstype !== "warranty" &&
                                    <>
                                        <th className='show_tab'>Specs</th>
                                        <th className='show_tab'>Images</th>
                                    </>
                                }
                                <th className='show_tab'>Overview</th>
                                <th>Stock Availability</th>
                                <th>Price</th>
                                <th>MRP</th>
                            </tr>
                            {presetdata.map((pr, i) =>
                                pstype === "margin" ? (
                                    <tr key={i}>
                                        <td>{pr.kmar}</td>
                                    </tr>
                                ) : (
                                    <tr key={i}>
                                        <td><KuroLink to={'/presets/' + pstype + '/' + pr.presetid}>{pr.presetid}</KuroLink></td>
                                        <td>{pr.title}</td>
                                        <td>{pr.kuro_title}</td>
                                        <td>{pr.portal_title}</td>
                                        <td className='show_tab'>{pr.short_title}</td>
                                        {pstype !== "os" && pstype !== "shp_fees" && pstype !== "build_fees" && pstype !== "warranty" &&
                                            <>
                                                <td className='show_tab' style={{ whiteSpace: "nowrap" }}>
                                                    {Object.keys(pr.specs).map((spec) =>
                                                        <>{psspecs.filter(_ => _.type === pstype)[0].specs.filter(_ => _.id === spec)[0]?.text}:&nbsp;
                                                            {typeof (pr.specs[spec]) === "boolean" ? (pr.specs[spec] === true ? 'Yes' : 'No') : pr.specs[spec]}
                                                            {psspecs.filter(_ => _.type === pstype)[0].specs.filter(_ => _.id === spec)[0]?.postfix && psspecs.filter(_ => _.type === pstype)[0].specs.filter(_ => _.id === spec)[0]?.postfix}
                                                            <br />
                                                        </>
                                                    )}
                                                </td>
                                                <td className='show_tab' style={{ whiteSpace: "nowrap" }}>{pr.images.map(img => <><a href={img}>{img}</a><br /></>)}</td>
                                            </>
                                        }
                                        <td className='show_tab'>
                                            {pr.overview.map((ov, i) =>
                                                <span key={i}>
                                                    {ov.title && <span>{ov.title}<br></br></span>}
                                                    {ov.subtitle && <span>{ov.subtitle}<br></br></span>}
                                                    {ov.desc && <span>{ov.desc}</span>}
                                                </span>
                                            )}
                                        </td>
                                        <td>{pr.status}</td>
                                        <td>{pr.price}</td>
                                        <td>{pr.mrp}</td>
                                    </tr>
                                )
                            )}
                        </tbody>
                    </table>
                </div>
            }
            <p>&nbsp;</p>
        </div>
    )
}

const mapStateToProps = state => ({
    user: state.user
})

export default connect(mapStateToProps)(Presets)