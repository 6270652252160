import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import KuroLink from '../components/common/KuroLink'
import '../styles/table.css'

const Profile = ({user: {token}}) => {

    const [empdata, setempdata] = useState(null)

    useEffect(() => {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            },
        };

        axios(process.env.REACT_APP_KC_API_URL + 'empprofile', config)
        .then((res) => {
            setempdata(res.data)
        });
    }, [])

    return (
        <div className='profile mx-width txt-light'>
            <h2>Profile</h2>
            <table className='border' cellPadding="0" cellSpacing="0">
                {empdata !== null && empdata.map((emp, i) =>
                <tbody>
                    <tr>
                        <td>Emp ID</td>
                        <td>{emp.userid}</td>
                    </tr>
                    <tr>
                        <td>Name</td>
                        <td>{emp.name}</td>
                    </tr>
                    <tr>
                        <td>Mobile No.</td>
                        <td>{emp.phone}</td>
                    </tr>
                    <tr>
                        <td>Email Address</td>
                        <td>{emp.email}</td>
                    </tr>
                    <tr>
                        <td>Username</td>
                        <td>{emp.Username}</td>
                    </tr>
                    <tr>
                        <td>Phone Verified</td>
                        <td>{emp.phoneVerified}</td>
                    </tr>
                    <tr>
                        <td>Gender</td>
                        <td>{emp.gender}</td>
                    </tr>
                    <tr>
                        <td>Date of Birth</td>
                        <td>{emp.dob}</td>
                    </tr>
                    <tr>
                        <td>Permanent Address</td>
                        <td>{emp.permadd_line1}<br/>
                            {emp.permadd_line2}<br/>
                            {emp.permadd_city}<br/>
                            {emp.permadd_state}<br/>
                            {emp.permadd_pincode}</td>
                    </tr>
                    <tr>
                        <td>Present Address</td>
                        <td>{emp.presadd_line1}<br/>
                            {emp.presadd_line2}<br/>
                            {emp.presadd_city}<br/>
                            {emp.presadd_state}<br/>
                            {emp.presadd_pincode}</td>
                    </tr>
                    <tr>
                        <td>Emergency Contact Name</td>
                        <td>{emp.emerg_name}</td>
                    </tr>
                    <tr>
                        <td>Emergency Contact Number</td>
                        <td>{emp.emerg_phone}</td>
                    </tr>
                    <tr>
                        <td>ID Proof Type</td>
                        <td>{emp.idproof_type}</td>
                    </tr>
                    <tr>
                        <td>ID Proof Number</td>
                        <td>{emp.idproof_number}</td>
                    </tr>
                    <tr>
                        <td>PAN No</td>
                        <td>{emp.pan}</td>
                    </tr>
                    <tr>
                        <td>Bank Account Details</td>
                        <td>{emp.bank_accountno}<br/>
                            {emp.bank_name}<br/>
                            {emp.bank_branch}<br/>
                            {emp.bank_ifsc}</td>
                    </tr>
                    <tr>
                        <td>Joining Date</td>
                        <td>{emp.joining_date}</td>
                    </tr>
                </tbody>
                )}
            </table>
            <p>&nbsp;</p>
            <div className='btn'>
            <KuroLink to='pwd-reset'><button>Change Password</button></KuroLink>
            </div>
            <p>&nbsp;</p>
        </div>
    )
}

const mapStateToProps = (state) => ({
    user: state.user
});

export default connect(mapStateToProps)(Profile)