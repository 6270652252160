import Hero from '../components/portal/Hero'
import '../styles/portaleditor.css'

const PortalEditor = () => {

  return (
    <div>
      <h1 className="txt-light">Portal Editor</h1>
      <Hero />
    </div>
  )
}

export default PortalEditor