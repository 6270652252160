import { Switch, Route, useLocation } from 'react-router';
import { useEffect } from 'react';
import AuthenticatedRoute from "../common/AuthenticatedRoute";
import Audit from '../../pages/Audit';
import BulkPayments from '../../pages/BulkPayments'
import ChangePwd from '../../pages/ChangePwd';
import CreateEstimate from '../../pages/CreateEstimate';
import CreateInwardDNote from '../../pages/CreateInwardDNote';
import CreateInwardCNote from '../../pages/CreateInwardCNote';
import CreateInwardInvoice from '../../pages/CreateInwardInvoice';
import CreateTPBuild from '../../pages/CreateTPBuild';
import Estimates from '../../pages/Estimates';
import GenerateInvoice from '../../pages/GenerateInvoice';
import Home from '../../pages/Home';
import InvoiceCredit from '../../pages/InvoiceCredit'
import Inventory from '../../pages/Inventory';
import Inventorydetails from '../../pages/Inventorydetails';
import InwardDebitNote from '../../pages/InwardDebitNote';
import InwardDebitNotes from '../../pages/InwardDebitNotes';
import InwardCreditNote from '../../pages/InwardCreditNote';
import InwardCreditNotes from '../../pages/InwardCreditNotes';
import InwardInvoice from '../../pages/InwardInvoice';
import InwardInvoices from '../../pages/InwardInvoices';
import InwardPayments from '../../pages/InwardPayments';
import InwardPayment from '../../pages/InwardPayment';
import OutwardInvoices from '../../pages/OutwardInvoices';
import OutwardInvoice from "../../pages/OutwardInvoice"
import Login from '../../pages/Login';
import OutwardDebitNotes from '../../pages/OutwardDebitNotes'
import NotFoundPage from '../../pages/NotFoundPage';
import NPSEstimate from '../../pages/NPSEstimate';
import OfflineOrders from '../../pages/OfflineOrders';
import OfflineOrder from '../../pages/OfflineOrder';
import OfflineOrderInvoice from '../../pages/OfflineOrderInvoice';
import CreatePO from '../../pages/CreatePO'
import PurchaseOrder from '../../pages/PurchaseOrder'
import PurchaseOrders from '../../pages/PurchaseOrders'
import OfflineOrderInventory from '../../pages/OfflineOrderInventory';
import CreatePV from '../../pages/CreatePV'
import CreateOutwardDNote from '../../pages/CreateOutwardDNote'
import OfflineOrderStatus from '../../pages/OfflineOrderStatus'
import Orders from '../../pages/Orders'
import EstimateOrder from '../../pages/EstimateOrder'
import TPOrderInventory from '../../pages/TPOrderInventory'
import TPOrderProducts from '../../pages/TPOrderProducts'
import Outward from '../../pages/Outward'
import Estimate from '../../pages/Estimate'
import Products from '../../pages/Products'
import Profile from '../../pages/Profile'
import Service from '../../pages/Service'
import StockColl from '../../pages/StockColl'
import StockProd from '../../pages/StockProd'
import StockRegister from '../../pages/StockRegister'
import TPOrder from '../../pages/TPOrder'
import TPOrders from '../../pages/TPOrders'
import TPBuild from '../../pages/TPBuild'
import TPBuilds from '../../pages/TPBuilds'
import UserDetails from '../../pages/UserDetails'
import UserOrders from '../../pages/UserOrders'
import Users from '../../pages/Users'
import Vendor from '../../pages/Vendor'
import Vendors from '../../pages/Vendors'
import Unauthorized from './Unauthorized'
import Employees from "../../pages/Hr/Employees"
import EmployeeAccessLevel from '../../pages/Hr/EmployeeAccessLevel'
import OutwardCreditNotes from '../../pages/OutwardCreditNotes'
import EmployeeAccessLevelUpdate from '../../pages/Hr/EmployeeAccessLevelUpdate'
import PaymentVoucher from '../../pages/PaymentVoucher'
import PaymentVouchers from '../../pages/PaymentVouchers'
import CreateEmp from "../../pages/Hr/CreateEmp";
import JobApps from "../../pages/Hr/JobApps"
import Attendance from "../../pages/Hr/Attendence"
import Dashboard from "../../pages/Hr/Dashboard"
import EditAttendance from "../../pages/Hr/EditAttendance"
import Presets from "../../pages/Products/Presets"
import AddPreset from "../../pages/Products/AddPreset"
import Preset from '../../pages/Products/Preset';
import ReplacePresetValues from '../../pages/Products/ReplacePresetValues'
import ProdFinder from '../../pages/Products/ProdFinder'
import PreBuiltsFinder from '../../pages/Products/PreBuiltsFinder'
import KGProd from '../../pages/KGProd'
import IndentList from '../../pages/IndentList'
import Editbatch from '../../pages/editbatch'
import Exportdata from '../../pages/Exportdata'
import Counters from '../../pages/Counters'
import PortalEditor from '../../pages/PortalEditor'
import ServiceRequest from "../../pages/ServiceRequest"
import Peripherals from '../../pages/Peripherals'
import CreatePaymentLink from "../../pages/CreatePaymentLink";
import TPOrderInvoice from '../../pages/TPOrderInvoice'
import SearchResults from '../../pages/SearchResults';
import EmployeesSalary from '../../pages/EmployeesSalary';
import Financials from '../../pages/Financials';
import ItcGst from '../../pages/ItcGst';
const Routes = () => {

    let location = useLocation()

    useEffect(() => {
        if (location.pathname !== "/login") {
            sessionStorage.setItem("path", location.pathname)
        }
    }, [location])

    return (
        <Switch>
            <AuthenticatedRoute exact path="/" component={Home} />
            <Route exact path="/login" component={Login} />
            <AuthenticatedRoute exact path="/attendance" component={Attendance} />
            <AuthenticatedRoute exact path="/attendance-dashboard" component={Dashboard} />
            <AuthenticatedRoute exact path="/edit-attendance/:userid" component={EditAttendance} />
            <AuthenticatedRoute exact path="/user/profile" component={Profile} />
            <AuthenticatedRoute exact path="/audit" component={Audit} />
            <AuthenticatedRoute exact path="/bulk-payments" component={BulkPayments} />
            <AuthenticatedRoute exact path="/user/pwd-reset" component={ChangePwd} />
            <AuthenticatedRoute exact path="/create-inward-invoice" component={CreateInwardInvoice} />
            <AuthenticatedRoute exact path="/inward-invoices" component={InwardInvoices} />
            <AuthenticatedRoute exact path="/inward-invoices/:invoiceid" component={InwardInvoice} />
            <AuthenticatedRoute exact path="/create-inward-creditnote" component={CreateInwardCNote} />
            <AuthenticatedRoute exact path="/create-tpbuilds" component={CreateTPBuild} />
            <AuthenticatedRoute exact path="/inward-creditnotes" component={InwardCreditNotes} />
            <AuthenticatedRoute exact path="/inward-creditnotes/:creditnoteid" component={InwardCreditNote} />
            <AuthenticatedRoute exact path="/create-inward-debitnote" component={CreateInwardDNote} />
            <AuthenticatedRoute exact path="/employees" component={Employees} />
            <AuthenticatedRoute exact path="/emps-salary" component={EmployeesSalary} />
            <AuthenticatedRoute exact path="/inward-debitnotes" component={InwardDebitNotes} />
            <AuthenticatedRoute exact path="/inward-debitnotes/:debitnoteid" component={InwardDebitNote} />
            <AuthenticatedRoute exact path="/generate-invoice" component={GenerateInvoice} />
            <AuthenticatedRoute exact path="/tporder-invoice/:orderid" component={TPOrderInvoice} />
            <AuthenticatedRoute exact path="/inward-payments" component={InwardPayments} />
            <AuthenticatedRoute exact path="/inward-payments/:orderid" component={InwardPayment} />
            <AuthenticatedRoute exact path="/invoice-credit/:fin_year/:invoice_no" component={InvoiceCredit} />
            <AuthenticatedRoute exact path="/outward-invoices" component={OutwardInvoices} />
            <AuthenticatedRoute exact path="/createPaymentLink/:orderid" component={CreatePaymentLink} />
            <AuthenticatedRoute exact path="/outward-invoices/:fin_year/:invoice_no" component={OutwardInvoice} />
            <AuthenticatedRoute exact path="/vendors" component={Vendors} />
            <AuthenticatedRoute exact path="/vendors/:vendorCode" component={Vendor} />
            <AuthenticatedRoute exact path="/tporders" component={TPOrders} />
            <AuthenticatedRoute exact path="/tporders/:orderId" component={TPOrder} />
            <AuthenticatedRoute exact path="/presets" component={Presets} />
            <AuthenticatedRoute exact path="/add-preset" component={AddPreset} />
            <AuthenticatedRoute exact path="/outward-debitnotes" component={OutwardDebitNotes} />
            <AuthenticatedRoute exact path="/create-outward-debitnotes" component={CreateOutwardDNote} />
            <AuthenticatedRoute exact path="/outward-creditnotes" component={OutwardCreditNotes} />
            <AuthenticatedRoute exact path="/presets/:pstype/:presetid" component={Preset} />
            <AuthenticatedRoute exact path="/counters" component={Counters} />
            <AuthenticatedRoute exact path="/replace-preset-values" component={ReplacePresetValues} />
            <AuthenticatedRoute exact path="/productfinder" component={ProdFinder} />
            <AuthenticatedRoute exact path="/prebuiltsfinder" component={PreBuiltsFinder} />
            <AuthenticatedRoute exact path="/indent-list" component={IndentList} />
            <AuthenticatedRoute exact path="/batches/:batchid" component={Editbatch} />
            <AuthenticatedRoute exact path="/exportdata" component={Exportdata} />
            <AuthenticatedRoute exact path="/estimate-order/:estimate_no/:version" component={EstimateOrder} />
            <AuthenticatedRoute exact path="/financials" component={Financials} />
            <AuthenticatedRoute exact path="/offlineorders" component={OfflineOrders} />
            <AuthenticatedRoute exact path="/offlineorders/:orderId" component={OfflineOrder} />
            <AuthenticatedRoute exact path="/offlineorder-invoice/:orderid" component={OfflineOrderInvoice} />
            <AuthenticatedRoute exact path="/offlineorders/add-inventory/:orderId" component={OfflineOrderInventory} />
            <AuthenticatedRoute exact path="/offlineorder-status/:orderid" component={OfflineOrderStatus} />
            <AuthenticatedRoute exact path="/orders" component={Orders} />
            <AuthenticatedRoute exact path="/payment-vouchers" component={PaymentVouchers} />
            <AuthenticatedRoute exact path="/payment-vouchers/:pv_no" component={PaymentVoucher} />
            <AuthenticatedRoute exact path="/create-estimate" component={CreateEstimate} />
            <AuthenticatedRoute exact path="/tporders/add-products/:orderId" component={TPOrderProducts} />
            <AuthenticatedRoute exact path="/tporders/add-inventory/:orderId" component={TPOrderInventory} />
            <AuthenticatedRoute exact path="/products" component={Products} />
            <AuthenticatedRoute exact path="/estimates" component={Estimates} />
            <AuthenticatedRoute exact path="/nps/estimates/:estimate_no" component={NPSEstimate} />
            <AuthenticatedRoute exact path="/nps/estimates/:estimate_no/:version" component={NPSEstimate} />
            <AuthenticatedRoute exact path="/estimates/:estimate_no" component={Estimate} />
            <AuthenticatedRoute exact path="/estimates/:estimate_no/:version" component={Estimate} />
            <AuthenticatedRoute exact path="/stock/inventory" component={Inventory} />
            <AuthenticatedRoute exact path="/stock/inventory/details" component={Inventorydetails} />
            <AuthenticatedRoute exact path="/stock/outward" component={Outward} />
            <AuthenticatedRoute exact path="/stock-register" component={StockRegister} />
            <AuthenticatedRoute exact path="/stock-register/:prodColl" component={StockColl} />
            <AuthenticatedRoute exact path="/stock-register/:prodColl/:prodType" component={StockProd} />
            <AuthenticatedRoute exact path="/tpbuilds" component={TPBuilds} />
            <AuthenticatedRoute exact path="/tpbuilds/:buildId" component={TPBuild} />
            <AuthenticatedRoute exact path="/service-request" component={Service} />
            <AuthenticatedRoute exact path="/servicerequest/:srid" component={ServiceRequest} />
            <AuthenticatedRoute exact path="/users" component={Users} />
            <AuthenticatedRoute exact path="/create-pv" component={CreatePV} />
            <AuthenticatedRoute exact path="/create-po" component={CreatePO} />
            <AuthenticatedRoute exact path="/users/:userId" component={UserDetails} />
            <AuthenticatedRoute exact path="/orders/:userId" component={UserOrders} />
            <AuthenticatedRoute exact path="/purchase-orders" component={PurchaseOrders} />
            <AuthenticatedRoute exact path="/purchase-orders/:po_no" component={PurchaseOrder} />
            <AuthenticatedRoute exact path="/create-emp" component={CreateEmp} />
            <AuthenticatedRoute exact path="/portaleditor" component={PortalEditor} />
            <AuthenticatedRoute exact path="/employee-accesslevel" component={EmployeeAccessLevel} />
            <AuthenticatedRoute exact path="/employee-accesslevel/:empid/:name" component={EmployeeAccessLevelUpdate} />
            <AuthenticatedRoute exact path="/job-apps" component={JobApps} />
            <AuthenticatedRoute exact path="/peripherals" component={Peripherals} />
            <AuthenticatedRoute exact path="/peripherals/:prodColl" component={KGProd} />
            <AuthenticatedRoute exact path="/peripherals/:prodColl/:prodType" component={KGProd} />
            <AuthenticatedRoute exact path="/search-results" component={SearchResults} />
            <AuthenticatedRoute exact path="/itc-gst" component={ItcGst} />
            <AuthenticatedRoute exact path="/unauthorized" component={Unauthorized} />
            <Route path='*' component={NotFoundPage} />

        </Switch>
    )
}

export default Routes